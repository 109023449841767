<template>
  <div class="camera-guide" @click.stop="close">
    <div class="camera-guide__main">
      <div class="font-center" @click.stop style="margin: 10px;">
        <el-radio-group v-model="type">
          <el-radio-button label="new">报到流程</el-radio-button>
          <el-radio-button label="edit">修改/上传门禁照片</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex-center camera-guide__body">
      <div v-show="type==='new'" style="display: grid;gap:10px;grid-template-columns: repeat(2,1fr)">
        <img src="/images/camera/guide.png" alt=""  style="width:100%;" />
        <img src="/images/camera/guide3.png" alt="" style="width:100%;" />
      </div>
      <img v-show="type==='edit'" src="/images/camera/guide2.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cameraGuide',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 'new'// new 报到 ，edit 上传门禁照片
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.camera-guide {
  position: absolute;
  left: 0;
  top: -30px;
  bottom: -30px;
  width: 100%;
  //height: 100%;
  background: rgba(0, 0, 0, .55);
  z-index: 999;
  overflow: hidden;

  .camera-guide__main {
    position: relative;
    width: 100%;
    height: 100%;
  }
.camera-guide__body{
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  img {
    //position: absolute;
    //left: 50%;
    //top: 50px;
    width: 100%;
    max-width: 700px;
    object-fit: contain;
    //transform: translateX(-50%);
  }
}
</style>
