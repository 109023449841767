<template>
  <div class="camera">
    <div class="camera-box">
      <div v-show="!photo" ref="videoBoxRef" class="video-box"
           v-loading="cameraLoading"
           :element-loading-text="loadingText"
      >
        <video ref="videoRef" autoplay></video>
      </div>
      <div v-show="photo" class="camera-box__preview">
        <!--    canvas 用于生成照片    -->
        <canvas ref="canvas" style="display:none;"></canvas>
        <ImageCropper ref="cropperRef" :image-url="photo" :cropOption="cropOption"
                      @on-update="onUpdate" />
      </div>
    </div>

    <div class="flex" style="justify-content: space-between; padding-top: 20px">
      <div style="width: 40px;">&nbsp;</div>
      <div>
        <el-button v-permission="['dorm:check-in:photo','dormitoryBed:edit','secondCollege:bed:edit']"
                   :disabled="cameraLoading"
                   type="primary" plain icon="el-icon-camera" size="large" @click="takePicture">
          {{ photo ? '重拍' : '拍照' }}
        </el-button>
        <el-button v-permission="['dorm:check-in:photo','dormitoryBed:edit','secondCollege:bed:edit']"
                   v-if="userType===2&&isQrCode"
                   style="margin-left: 20px;"
                   :disabled="cameraLoading" type="primary" plain icon="el-icon-aim" size="large"
                   @click="handleScan">
          二维码识别
        </el-button>
      </div>

      <el-button v-permission="['dorm:check-in:photo','dormitoryBed:edit','secondCollege:bed:edit']"
                 :disabled="!photo||loading" type="primary" size="large" icon="el-icon-upload" @click="upload">
        {{ userType === 3 ? '上传' : isQrCode ? '上传并报到' : '上传' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import ImageCropper from '@/components/common/components/ImageCropper.vue'
import { throttle } from 'lodash'
import jsQR from 'jsqr'
import camera from '@/components/common/components/camera.vue'

export default {
  name: 'camera',
  computed: {
    camera() {
      return camera
    }
  },
  components: { ImageCropper },
  props: {
    userType: {
      type: Number,
      default: 2
    },
    loading: {
      type: Boolean,
      default: false
    },
    isQrCode: Number
  },
  data() {
    return {
      photo: '',
      cameraLoading: false,
      loadingText: '请将手机二维码对准摄像头，并保持保持20-30cm距离。/n 按下“ESC键”可以停止识别。',
      cropOption: {
        width: 360,
        deleteLeft: 230,
        updateLeft: 180,
        disabled: false
      },
      //  reqestCam= 用于打断动画
      reqestCam: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initCamera()
    })
  },
  methods: {
    // 扫描二维码 chrome不支持
    handleScan() {
      // this.$emit('update:loadingText', '')
      this.loadingText = '请将手机二维码对准摄像头，并保持保持20-30cm距离。按下“ESC键”可以停止识别。'
      this.cameraLoading = true
      let times = 0
      // this.$emit('update:loading', true)
      const canvas = this.$refs.canvas
      const video = this.$refs.videoRef
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      /* Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true.
      * 这个警告大概的意思是 使用getImageData的多次读回操作会更快，建议将willReadFrequency属性设置为true。 { willReadFrequently: true }
      * */
      const ctx = canvas.getContext('2d', { willReadFrequently: true })
      const scanningLoop = () => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        const qrCodeImg = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const qrCodeData = jsQR(qrCodeImg.data, canvas.width, canvas.height, {
          inversionAttempts: 'dontInvert'
        })
        // ctx.clearRect(0, 0, canvas.width, canvas.height)
        if (qrCodeData) {
          this.loadingText = '二维码识别成功！数据传输中...'
          this.$emit('getStuInfo', qrCodeData.data)
          // this.$message.success('二维码识别成功！' + qrCodeData.data)
          this.stopScan()
        } else {
          // 10秒内无法识别二维码，退出识别
          if (times > 500) {
            this.$message.error('未找到二维码')
            this.$emit('update:loading', false)
            this.$emit('update:loadingText', '')
            this.cameraLoading = false
          } else {
            times++
            // Web API
            this.reqestCam = requestAnimationFrame(scanningLoop)
          }
        }
      }
      scanningLoop()
    },
    // 停止扫描
    stopScan() {
      this.$emit('update:loading', false)
      this.$emit('update:loadingText', '')
      this.cameraLoading = false
      cancelAnimationFrame(this.reqestCam)
      this.reqestCam = null
      // if (this.$refs.videoRef?.srcObject) {
      //   this.$refs.videoRef.srcObject.getTracks().forEach(track => track.stop())
      //   this.$refs.videoRef.srcObject = null
      // }
    },
    // 唤醒摄像头 trailing=false 结束后不执行
    async initCamera() {
      try {
        // 首先获取现存的getUserMedia(如果存在)
        const getUserMedia = navigator.mediaDevices ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.getUserMedia

        const devices = await getUserMedia.enumerateDevices()
        const findCamera = devices.some(device => device.kind === 'videoinput')
        if (!findCamera) {
          this.$confirm('未检测到摄像头，请检查设备！').then()
          return
        }
        // 有些浏览器不支持，会返回错误信息
        // 保持接口一致
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          )
        }

        const stream = await getUserMedia.getUserMedia({
          audio: true,
          video: {
            width: 640,
            height: 480
          }
        })
        this.stream = stream
        this.$refs.videoRef.srcObject = stream
      } catch (error) {
        console.error('Error accessing camera:', error)
        this.$confirm('摄像头启动失败，可能被其他浏览器占用，请先关闭其闭其他浏览器并刷新页面。').then()
      }
    },
    takePicture: throttle(function() {
      this.cameraLoading = true
      if (this.photo) {
        this.clear()
        this.cameraLoading = false
        return
      }
      this.$emit('update:loading', true)
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d', { willReadFrequently: true })
      const video = this.$refs.videoRef
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      // 识别二维码时
      // 返回拍照信息
      this.photo = canvas.toDataURL('image/png')
      this.$emit('update:loading', false)
      this.cameraLoading = false
    }, 300, { trailing: false }),
    // 清空画布
    clear() {
      this.photo = ''
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      this.$refs.cropperRef.previewUrl = ''
    },
    // 更新图片链接
    onUpdate(imgUrl) {
      this.$emit('on-update', imgUrl)
    },
    // 上传图片到minio
    upload() {
      this.$refs.cropperRef.upload()
    }
  }
}
</script>

<style lang="scss" scoped>
.camera {
  ::v-deep .el-loading-mask {
    background: rgba(0, 0, 0, .2);

    .el-loading-text {
      background: rgba(255, 255, 255, .9);
    }
  }

  .camera-box {
    //@include flex;
    //align-items: center;
    //justify-content: center;
    width: 574px;
    height: 430px;
    overflow: hidden;

    .video-box, .camera-box__preview {
      width: 640px;
      height: 480px;
      transform: scale(.89);
      transform-origin: 0 0;
    }

    .video-box {
      height: 480px;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .camera-box__preview {
      img {
        border-radius: 4px;
      }
    }
  }
}
</style>
