<template>
  <div class="check-in-photo" v-loading="loading"
       :element-loading-text="loadingText">
    <camera-guide v-if="visibleGuide" :visible.sync="visibleGuide" />
    <div v-if="visibleSearchBar" class="shortcut-key fr mr-1 mt-1">
      <el-popover
        placement="left"
        width="300"
        title="快捷键"
        trigger="hover">
        <el-descriptions :column="1" border>
          <el-descriptions-item label-class-name="descriptions-item__label" label="停止二维码识别/重拍">
            Esc
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="选中搜索栏">
            Ctrl+Shift+F
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="二维码识别">
            Ctrl+Shift+/
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="拍照">
            双击Space（空格键）
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="上传照片/报到">
            Ctrl+Shift+⬆箭头
          </el-descriptions-item>
        </el-descriptions>
        <template slot="reference">
          <el-button size="small">快捷键</el-button>
        </template>
      </el-popover>
      <el-button class="ml-1" size="small" @click="()=>visibleGuide=true">教程</el-button>
    </div>
    <el-card>
      <el-descriptions :column="3" border>
        <el-descriptions-item label-class-name="descriptions-item__label is-empty"
                              content-class-name=" is-empty" />
        <el-descriptions-item label-class-name="descriptions-item__label is-empty"
                              content-class-name=" is-empty" />
        <el-descriptions-item label-class-name="descriptions-item__label is-empty"
                              content-class-name=" is-empty" />
        <el-descriptions-item v-if="visibleSearchBar" :span="3" label-class-name="descriptions-item__label">
          <template slot="label">
            <i class="el-icon-search"></i> 搜索
          </template>
          <div style="display: inline-block;">
            <tool-tips-group
              tips-content="名字搜索最少输入2个汉字。<br/>
                         身份证/录取编号搜索最少输入6位数字。<br/>
                         新生报到支持扫码报到（须在“智慧迎新h5”生成报到二维码）。<br/>
                         门禁照片补录请选择“照片录入”。<br/>"
            >
              <!--        <br/>只能搜索所管辖学院的学生。  <span>搜索学生：</span>-->
              <el-radio-group class="mr-1" v-model="formData.userType" size="small" @change="handleChange">
                <el-radio-button :label="2">搜索学生</el-radio-button>
                <el-radio-button :label="3">搜索教职工</el-radio-button>
              </el-radio-group>
              <el-autocomplete
                ref="autocompleteRef"
                v-model.trim="userInfo.value"
                :fetch-suggestions="querySearchAsync"
                :placeholder="formData.userType===2?'学生名、身份证号、录取编号':'教职工姓名、身份证、工号'"
                size="small"
                clearable
                @select="handleSelect" style="display: inline-block;width:288px;" />
            </tool-tips-group>
          </div>
          <el-radio-group v-if="formData.userType===2" class="ml-1" v-model="isQrCode" size="small">
            <el-radio-button :label="1">新生报到</el-radio-button>
            <el-radio-button :label="0">照片录入</el-radio-button>
          </el-radio-group>
          <!-- <label>新生报到</label>-->
          <!-- <el-switch-->
          <!--   class="ml-1"-->
          <!--   v-model="isQrCode"-->
          <!--   active-text="新生报到"-->
          <el-button size="small" class="fr" @click="clearUserSelect">清空用户信息</el-button>
        </el-descriptions-item>
        <!--     学生信息 start   -->
        <template v-if="formData.userType===2">
          <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
            {{ userInfo.data.stuName }}
            <el-tag class="ml-1" type="success" v-if="userInfo.data.signIn">已报到</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="录取编号">
            {{ userInfo.data.examineNo }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="年级">
            {{ userInfo.data.grade }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="学院">
            {{ userInfo.data.collegeName }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="专业">
            {{ userInfo.data.majorName }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item__label" label="班级">
            {{ userInfo.data.className }}
          </el-descriptions-item>
        </template>
        <!--     学生信息 end   -->
        <!--     教职工信息 start   -->
        <template v-if="formData.userType===3">
          <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
            {{ userInfo.data.realName }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="descriptions-item__label" label="教工号">
            {{ userInfo.data.username }}
          </el-descriptions-item>
        </template>
        <!--     教职工信息 end   -->
        <el-descriptions-item :span="3" label-class-name="descriptions-item__label"
                              content-class-name="descriptions__content-camera" label="拍照" style="padding: 0;">
          <div class="flex">
            <camera ref="cameraRef" :loading.sync="loading" :user-type="formData.userType" :is-qr-code.sync="isQrCode"
                    class="flex-1 "
                    :loading-text.sync="loadingText"
                    @on-update="handleUpdate" @getStuInfo="getStuInfoBySignId">
            </camera>
            <div class="camera__right">
              <el-descriptions direction="vertical" :column="1" border>
                <el-descriptions-item :label="formData.userType===2?'高考照片':'用户照片'">
                  <div class="preview-img-box"
                  style="margin: 0 auto;">
                    <el-image
                      v-if="userInfo.data.stuExamImg"
                      :src="`${$fileUrl}${userInfo.data.stuExamImg}`"
                      :preview-src-list="[`${$fileUrl}${userInfo.data.stuExamImg}`]" fit="contain"
                    />
                    <el-image
                      v-if="userInfo.data.realImg"
                      :src="`${$fileUrl}${userInfo.data.realImg}`"
                      :preview-src-list="[`${$fileUrl}${userInfo.data.realImg}`]" fit="contain"
                      style="margin: 0 auto;"
                    />
                  </div>
                </el-descriptions-item>
                <el-descriptions-item :label="formData.userType===2?'学籍照片':'门禁照片'">
                  <div class="preview-img-box" style="margin: 0 auto;">
                    <el-image
                      v-if="userInfo.data.headshot"
                      :src="`${$fileUrl}${userInfo.data.headshot}`"
                      :preview-src-list="[`${$fileUrl}${userInfo.data.headshot}`]" fit="contain"
                    />
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
import Camera from '@/components/common/components/camera.vue'
import userAutocomplete from '@/vue/mixins/user-autocomplete'
import { getStudentById, getStudentBySignIdApi, stuSignInApi, updateStudentImageApi } from '@/api/student'
import { getUserInfoById, updateTeacherHeadshot } from '@/api/system/user-api'
import { throttle } from 'lodash'
import CameraGuide from '@/views/pages/recruitManage/check-in-photo/components/cameraGuide.vue'

export default {
  name: 'checkInPhoto',
  mixins: [userAutocomplete],
  components: {
    CameraGuide,
    Camera
  },
  props: {
    visibleSearchBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visibleGuide: false,
      isQrCode: 1, //
      loading: false,
      lastSpaceTime: 0, // 监听双击空格键
      lastEnterTime: 0, // 监听双击enter
      userInfo: {
        value: '',
        data: {}
      },
      loadingText: '', // 加载中 文案
      formData: { userType: 2 }
    }
  },
  watch: {
    isQrCode: function(newVal) {
      window.localStorage.setItem('isQrCode', newVal + '')
    }
  },
  mounted() {
    const _isQrCode = window.localStorage.getItem('isQrCode')
    if (_isQrCode) {
      this.isQrCode = +_isQrCode
    }
    // shift+f 触发搜索栏
    window.addEventListener('keydown', e => this.handleKeyTap(e))
  },
  destroyed() {
    window.removeEventListener('keydown', e => this.handleKeyTap(e))
  },
  methods: {
    // 远程搜索 获取用户id
    handleSelect(user) {
      // 2-学生
      if (this.formData.userType === 2) {
        if (+this.isQrCode && !user.signInId) {
          // 没有报到二维码的不能进行报到
          this.$confirm('学生必须先通过智慧迎新h5的生成报到二维码！').then()
          return false
        } else {
          this.userInfo.data = user
          this.formData.userId = user.userId
        }
      } else {
        this.userInfo.data = user
        this.formData.userId = user.id
      }
      this.userSelectDisabled = true
    },
    clearUserSelect() {
      this.userInfo.data = {}
      this.userInfo.value = ''
      this.$refs.cameraRef.clear()
    },
    async getStuInfoBySignId(signId) {
      try {
        const res = await getStudentBySignIdApi({ signInId: signId })
        this.$confirm(`识别成功，姓名：<b>${res.data.stuName}</b>。<br>点击确定加载学生信息。`, '二维码识别', {
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.userInfo.data = res.data
          this.formData.userId = res.data.userId
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleUpdate: throttle(async function(imgUrl) {
      if (!this.userInfo.data.id) {
        this.$message.warning('请先选择要上传照片的用户！')
        return
      }
      if (this.formData.userType === 2) {
        const params = { headshot: imgUrl }
        let apiMethod
        // 二维码报到 or 手动报到(手动也要学生先生成报到二维码)
        if (+this.isQrCode) {
          params.signInId = this.userInfo.data.signInId
          apiMethod = stuSignInApi
        } else {
          // 只是更新学籍照片
          params.id = this.userInfo.data.id
          apiMethod = updateStudentImageApi
        }
        try {
          await apiMethod(params)
          const res = await getStudentById(this.userInfo.data.id)
          this.userInfo.data = res.data
          this.$confirm(`上传成功！${+this.isQrCode ? '学生报到成功' : ''}！`, '报到成功！').then()
          this.$refs.cameraRef.clear()
        } catch (e) {
          console.error(e)
        }
      } else if (this.formData.userType === 3) {
        try {
          await updateTeacherHeadshot({
            id: this.userInfo.data.id,
            headshot: imgUrl
          })
          const res = await getUserInfoById(this.userInfo.data.id)
          this.userInfo.data = res.data
          this.$message.success('上传成功！')
          this.$refs.cameraRef.clear()
        } catch (e) {
          console.error(e)
        }
      }
    }, 1000),
    handleKeyTap(e) {
      const evt = e || window.event
      if (evt.keyCode === 32) {
        if (!this.lastSpaceTime) {
          this.lastSpaceTime = Date.now()
        } else {
          const currentTime = Date.now()
          // 如果两次按键间隔小于特定阈值，则认为是双击 执行拍照功能
          if (currentTime - this.lastSpaceTime < 300) {
            // eslint-disable-next-line no-unused-expressions
            this.$refs.autocompleteRef.focus()
            this.$refs.cameraRef.takePicture()
            this.lastSpaceTime = 0
            // 执行双击空格的操作
          } else {
            this.lastSpaceTime = Date.now()
          }
          this.lastSpaceTime = currentTime
        }
      } else if (evt.keyCode === 27) {
        // Esc 停止扫描 + 清空照片
        this.$refs.cameraRef.stopScan()
        this.$refs.cameraRef.clear()
      } else if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 70) {
        // ctrl+shift+ F  focus搜索条
        // eslint-disable-next-line no-unused-expressions
        this.$refs?.autocompleteRef?.focus()
      } else if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 191) {
        // ctrl+shift+ / 斜杠 二维码识别(必须isQrCode===1)
        this.isQrCode = 1
        this.formData.userType = 2
        this.$refs.cameraRef.handleScan()
      } else if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 38) {
        // ctrl+shift+ 上箭头 = 上传、报到
        this.$refs.cameraRef.upload()
      }
    },
    handleChange() {
      this.userInfo.data = {}
      this.userInfo.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.check-in-photo {
  position: relative;

  .el-card {
    width: 930px;
    margin: 3em auto;

    ::v-deep .el-descriptions {

      .is-empty {
        padding: 0;
      }

      .descriptions-item__label {
        width: 90px;
      }

      .descriptions__content-camera {
        padding: 0;

        .camera {
          margin: 13px 21px 0;
        }

        .camera__right {
          flex-shrink: 0;
          width: 180px;
          border-left: 1px solid #EBEEF5;

          .preview-img-box {
            width: 139px;
            //height: 238px;
            height: 185px;
          }

          .el-image {
            //width: 179px;
            width: 139px;

          }
        }
      }
    }
  }
}
</style>
