<template>
  <div class="cropper-container">
    <VueCropper
      ref="cropper"
      :img="imageUrl"
      :outputSize="option.size"
      :outputType="option.outputType"
      :info="true"
      :full="option.full"
      :canMove="false"
      :can-scale="option.canScale"
      :canMoveBox="option.canMoveBox"
      :original="option.original"
      :autoCrop="option.autoCrop"
      :fixed="option.fixed"
      :auto-crop-width="option.autoCropWidth"
      :auto-crop-height="option.autoCropHeight"
      :fixedNumber="option.fixedNumber"
      :centerBox="option.centerBox"
      :infoTrue="option.infoTrue"
      :size="1"
      :fixedBox="option.fixedBox" />
<!--    <img :src="previewUrl" alt="" width="200" />-->
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import axios from 'axios'

export default {
  name: 'ImageCropper',
  props: ['on-update', 'imageUrl', 'cropOption'],
  components: {
    VueCropper
  },
  data() {
    return {
      previewUrl: '',
      // 裁剪配置
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 640, // 默认生成截图框宽度
        autoCropHeight: 480, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [3, 4], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false,
      // 裁剪过后的图片
      imgUrl: ''
    }
  },
  methods: {
    // 图片转file格式
    base64ToBlob(base64Data) {
      // 分割 Base64 字符串，获取 MIME 类型
      const parts = base64Data.split(',')
      const contentType = parts[0].match(/:(.*?);/)[1] // 获取 MIME 类型
      const suffix = contentType.split('/')[1]
      const raw = window.atob(parts[1]) // 解码 Base64 数据
      let n = raw.length
      // 将解码后的数据转换为 Uint8Array
      const buffer = new Uint8Array(n)
      while (n--) {
        buffer[n] = raw.charCodeAt(n)
      }
      // 创建 Blob 对象

      return new File([buffer], `${Date.now()}.${suffix}`, { type: contentType })
    },
    // 上传
    upload() {
      this.$refs.cropper.getCropData((imgData) => {
        this.loading = true
        // base64 转file 后，传入 formData内，才能上传
        const data = new FormData()
        data.append('file', this.base64ToBlob(imgData))
        axios.post(`${process.env.VUE_APP_API_URL}/auth/upload/image`, data, {
          headers: {
            Authorization: window.sessionStorage.getItem('token'),
            ContentType: 'multipart/form-data'
          }
        }).then(res => {
          this.previewUrl = process.env.VUE_APP_IMG_URL + res.data.data
          this.$emit('on-update', res.data.data)
        }).catch(e => {
          console.error(e)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cropper-container {
  width: 640px;
  height: 480px;
}
</style>
